import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import CRUDContext from '../components/CRUDContext';
// import DropDownTableComponent from "../components/DropDownTableComponent";
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';

import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';

import { setTempDataFun, emptyGlobalDataFun } from '../components/redux/actions';

import DropDownTableComponent from "../components/DropDownTableComponent";

import Cookies from 'js-cookie';

const StudentMasterDetailU = () => {
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';            
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);
    const [ListData, setListData] = useState([]);
    const [TransType, setTransType] = useState('');
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);  
    const [v_allow_new, setv_allow_new] = useState(2);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);    
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');
    const [AllowUpdateTag, setAllowUpdateTag] = useState(false);

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData);
    const ReduxDDStudentSectionData = useSelector((state) => state.DDStudentSectionData);    
    const ReduxDDStStreamData = useSelector((state) => state.DDStStreamData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);
    const ReduxDDReligionData = useSelector((state) => state.DDReligionData);
    const ReduxDDCasteCategData = useSelector((state) => state.DDCasteCategData);
    const ReduxDDCasteData = useSelector((state) => state.DDCasteData);
   
    // define drop downs inputs
    
    const [stream, setstream] = useState(0);
    const [st_class, setst_class] = useState(0);
    const [st_sec, setst_sec] = useState(0);

    const [religionItems, setreligionItems] = useState([]);
	const [religion_categoryItems, setreligion_categoryItems] = useState([]);
	const [religion_casteItems, setreligion_casteItems] = useState([]);
    const [full_con_categItems, setfull_con_categItems] = useState([]);

    const [FindClickStatus, setFindClickStatus] = useState(false);
    
    const [disableShow, setdisableShow] = useState(false);

    // const [exam_group_id, setexam_group_id ] = useState(0);
    // const [exam_group_idItems, setexam_group_idItems] = useState([]);

    const [headingcolumn, setheadingcolumn] = useState([]);

    const [showRemarksEntry, setShowRemarksEntry] = useState(0);

    const [showNavBar, setshowNavBar] = useState(true);    
        
    // When we will use Multiple selection in Drop Down
    
    // define drop downs items
    
    let v_input_result;

    // define variable for tables entry (master detail form)
    
    const [items, setItems] = useState([]); 
    
    const v_urlShowReportData = '50_url_student_update_view_fun';
    const v_urlSaveUpdateDelete = '50_url_student_update_view_fun_new';
    const v_backToNavigateName = '/Dashboard';
    // const v_findToNavigateName = '/Fcview';
    const v_main_form_heading = 'Student Details Update !';
    const v_frm_no = 50;

    const {
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme, 
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);
            
            setv_allow_new(result.allow_new);

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);                

                // console.log("Update allow_update >> ", result.allow_update)

                if (ReduxGlobalData[0].stem > 0){
                   setstream(ReduxGlobalData[0].stem);
                }                

                setreligionItems(ReduxDDReligionData[0]);            
                setreligion_categoryItems(ReduxDDCasteCategData[0]);
                setreligion_casteItems(ReduxDDCasteData[0]);

                const v_token = Cookies.get('token');
                
                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${ReduxGlobalData[0].br}/0/FULL_CON_CATEG`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setfull_con_categItems(await updatedata);

                setBlankFields();                
                
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();

        setst_class(0);
        setst_sec(0);
        setstream(0);
       
        setShowRemarksEntry(0);

    }, [ReduxGlobalData[0]]);
       
    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setAllowUpdateTag(false);
        setdisableSave(false);
        setItems([]);        
        setheadingcolumn([]);

        setshowNavBar(true);
    }

    const handleInputValueFun = (name, value) => {

        if (name === 'st_class') {
            setBlankFields();
                 
            setst_class(value);
           
            setShowRemarksEntry(0);

            return;
        }else if (name === 'st_sec') {

            setShowRemarksEntry(0);
            setBlankFields();

            setst_sec(value);            
            return;
        }else if (name === 'stream') {                  
            
            setBlankFields();            
            setShowRemarksEntry(0);    
            
            setstream(value);
            return;
        }  
    }       

    const getSavedDetailFunction = async () => {        

        setShowRemarksEntry(0);

        if(st_class === undefined || st_class.length === undefined || st_class.length === 0){
            toast.info("Entry required for Class", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });            
            return;
        }
        
        if(st_sec === undefined || st_sec.length === undefined || st_sec.length === 0){
            toast.info("Entry required for Class section", {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });            
            return;
        }

        if (parseInt(ReduxGlobalData[0].stem) === 0){
            if(stream === undefined || stream.length === undefined || stream.length === 0){
                toast.info("Entry required for Class stream", {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
                });            
                return;
            }            
        }
      
        setdisableShow(true);
        setBlankFields();
        
        const v_token = Cookies.get('token');           

        let v_active_status = 1, v_null_parameter = 'NA'; // for active records        

        response = await axios.get(`url_fetch_data/${v_urlShowReportData}/${v_frm_no}/${ReduxGlobalData[0].ag}/${ReduxGlobalData[0].br}/${ReduxGlobalData[0].fy}/${v_active_status}
        /${st_class}
        /${st_sec}
        /${ReduxGlobalData[0].brd}
        /${ReduxGlobalData[0].medi}
        /${stream}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}
        /${v_null_parameter}        
        `,
        { headers: { Authorization: `Bearer ${v_token}` } });
        updatedata = await response.data.v_out_data.v_data;        

        if (updatedata.length === 0){

            toast.info(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,
            });
            setdisableShow(false);
            return;
        }

        setItems(await updatedata);

        // console.log(updatedata)
                    
        // const column1 = Object.keys(await updatedata[0]);

        // setheadingcolumn(column1);

        setdisableShow(false);
    }

    const tdData =() =>{

        // const data = {               

        //     board:ReduxGlobalData[0].brd,
        //     student_type:ReduxGlobalData[0].st,
        //     medium:ReduxGlobalData[0].medi,
        //     fee_categ:ReduxGlobalData[0].fcat,
        //     pay_term:ReduxGlobalData[0].ptm,
            
        //     FindClickStatus,

        //     st_class, 
        //     st_sec,
        //     stream,          
        //     allow_new:userAuthData.allow_new   
        // }        

        // // console.log("1000");

        // return <StudentMasterDetailUDetails p_data = {data}/>   
                 
    }

    const handleInputFocus = (event) => {
        event.preventDefault();

        const { name, value } = event.target;    

    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        if (value === 'ADD_NEW_ROW'){
            
            // if(AllowUpdateTag){return;}
            return;
        }else if (value === 'BTN_NEW'){            
            // setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){            
           

        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){   
            e.preventDefault();         
            getSavedDetailFunction();
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }
    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };
    
    const goCheckDataFunction = (event) => {
        event.preventDefault();
        
        console.log("headingcolumn >> ", headingcolumn);

        console.log("Items >> ", items);

        // setp_till_month(6);

        // setItems(emptyListItems);

    }
    
    const handlerChangeTableRowFocus = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;
    };

    const handlerChange = (event, i, p_col_name) => {
        event.preventDefault();
        const { name, value } = event.target;        

        let v_value = value;

        const list = [...items];           

        if (name === 'st_aadhar_no' || name === 'height' || name === 'weight'){
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', v_value, '', 100);
            v_value = v_input_result.v_value;
        }else if (name === 'term_remarks'){                
            v_value = capitalizeWordFun(v_value);
        }

        list[i][name] = v_value; 
        setItems(list);
    };

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        
        let insupdel_type = '';       
        
        if (value === 'DELETE'){
            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                if (userAuthData.allow_update === 1){
                    setAllowUpdateTag(false);
                    insupdel_type = 'UPDATE';
                }else{
                    setAllowUpdateTag(true);
                    return;
                }                  
            }
        }

        if (value != 'DELETE'){

            // need to validate the date here           

        }

        if (items.length === 0){
            toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;
        }
        
        const data = {
            id: 0,
            insupdel_type:'INSERT',            
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,
            board : ReduxGlobalData[0].brd,
            medium : ReduxGlobalData[0].medi,
            stream,            
            st_class,
            st_sec,
            items: JSON.stringify(items), 
        };        
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }
    
    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);
            
            // console.log("updatedata.TabID.v_data >> ", updatedata.v_data);
            
            if (updatedata.transType === 'INSERT'){                         
                setsaveUpdateTag('UPDATE');
                setdisableSave(true);   
                v_toast_msg = createdMessage;
                getSavedDetailFunction();                
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;
                setdisableSave(true);   
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);                   
                v_toast_msg = deletedMessage;
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID, p_v_data) => {
    }
    
return (

<>  

{
    ShowConfirmModelSave ? 
    <div className="centerWindow_css">
        <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
    </div>
    : null
}

{
    ShowConfirmModelUpdateDelete ? 
    <div className="centerWindow_css w-[32%]">
        <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
    </div>
    : null
}

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css h-[10vh]">
        <div className="form_div_inner_css grid grid-cols-10">

            <div className="col-span-1">
                <label className="label_inner_page_css required_css">Class</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 0)}
                    // autoFocus         
                    name="st_class"
                    id="st_class"
                    value={st_class}
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        ReduxDDStudentClassData[0]?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div className="col-span-1">
                <label className="label_inner_page_css required_css">Sec.</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 0)}
                    // autoFocus         
                    name="st_sec"
                    id="st_sec"
                    value={st_sec}
                    onChange={handleInputValue}
                    onFocus={handleInputFocus}
                >
                    <option></option>
                    {
                        ReduxDDStudentSectionData[0]?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            {parseInt(ReduxGlobalData[0].stem) === 0 && (
                <div className="col-span-1">
                    <label className="label_inner_page_css required_css">Stream</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 0)}
                        // autoFocus         
                        name="stream"
                        id="stream"
                        value={stream}
                        onChange={handleInputValue}
                        onFocus={handleInputFocus}
                    >
                        <option></option>
                        {
                            ReduxDDStStreamData[0]?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}            

            <div className="mt-7">
                {(userAuthData.allow_read === 1 && !disableShow) && (
                    <button className="btn_find_css"
                        value = 'BTN_FIND'
                        onClick={handleButtonValue}>
                        Find
                    </button>
                )}
            </div>

        </div>        
        
    </div>

    {/* {tdData()} */}

    {/* Start Table */}

    <table className="table_main_css">
        <thead className="table_thead_css">            
            <tr className="table_thead_tr_css">
                <th className="table_th_td_css text-left w-[3%]"></th>
                <th className="table_th_td_css text-center w-[5%]">Adm.No.</th>        
                <th className="table_th_td_css text-left w-[19%]">Name</th>
                <th className="table_th_td_css text-left w-[18%]">Father Name</th>
                <th className="table_th_td_css text-left w-[10%]">Aadhar</th>                
                <th className="table_th_td_css text-left w-[11%]">Religion</th>                
                <th className="table_th_td_css text-left w-[11%]">Caste</th>
                <th className="table_th_td_css text-left w-[11%]">Category</th>
                <th className="table_th_td_css text-center w-[6%]">Height</th>
                <th className="table_th_td_css text-center w-[6%]">Weight</th>
            </tr>
        </thead>

        <tbody className="table_tbody_css h-[60vh]">
        {
            items?.map((item, index) => (                   

            <tr className="table_thead_tr_css table_tbody_hover_css" key={index}>

                <td className="table_th_td_css text-left w-[3%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    {index + 1}
                </td>            

                <td className="table_input_th_td_css w-[5%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                    <input className="table_input_inner_page_css text-center"
                        type="text"                
                        name="adm_no"
                        id="adm_no"
                        value={item.adm_no}                                           
                        disabled
                    ></input>
                </td>

                <td className="table_input_th_td_css text-left w-[19%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                    <input className="table_input_inner_page_css"
                        type="text"                
                        name="student_name"
                        id="student_name"
                        value={item.student_name}                                           
                        disabled
                    ></input>
                </td>

                <td className="table_input_th_td_css text-left w-[18%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                    <input className="table_input_inner_page_css"
                        type="text"                
                        name="fat_name"
                        id="fat_name"
                        value={item.fat_name}                                           
                        disabled
                    ></input>
                </td>
                
                <td className="table_input_th_td_css text-left w-[10%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                    <input className="table_input_inner_page_css"
                        type="text"
                        ref={(ref) => addInputRef(ref, 15)}
                        name="st_aadhar_no"
                        id="st_aadhar_no"
                        value={item.st_aadhar_no}                                                                   
                        onChange={(e) => handlerChange(e, index)}                                
                        disabled = {AllowUpdateTag}
                    ></input>                    
                </td>    

                <td className="table_input_th_td_css text-center w-[11%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <DropDownTableComponent 
                        ref={(ref) => addInputRef(ref, 14)}
                        paramItems={religionItems} columnName={'religion'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.religion}                     
                    />         
                </td> 

                <td className="table_input_th_td_css text-center w-[11%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <DropDownTableComponent 
                        ref={(ref) => addInputRef(ref, 14)}
                        paramItems={religion_casteItems} columnName={'religion_caste'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.religion_caste}                     
                    />         
                </td>

                <td className="table_input_th_td_css text-center w-[11%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}>
                    <DropDownTableComponent 
                        ref={(ref) => addInputRef(ref, 14)}
                        paramItems={religion_categoryItems} columnName={'religion_category'} fun1={(e) => handlerChange(e, index)} columnNameValue={item.religion_category}                     
                    />         
                </td>                                   

                <td className="table_input_th_td_css text-center w-[6%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                    <input className="table_input_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 15)}   
                        name="height"
                        id="height"
                        value={item.height}                                                                   
                        onChange={(e) => handlerChange(e, index)}                                
                        disabled = {AllowUpdateTag}
                    ></input>
                </td>

                <td className="table_input_th_td_css text-center w-[6%]" onFocus={(e) => handlerChangeTableRowFocus(e, index, '')}> 
                    <input className="table_input_inner_page_css text-center"
                        type="text"
                        ref={(ref) => addInputRef(ref, 15)}   
                        name="weight"
                        id="weight"
                        value={item.weight}                                                                   
                        onChange={(e) => handlerChange(e, index)}                                
                        disabled = {AllowUpdateTag}
                    ></input>
                </td>                

            </tr>
            ))
        }
        </tbody>
    </table>

{/* End Table */}

<nav className="btn_nav_inner_css">

    <div className="btn_div_main_css">       

        <div className="btn_div_inner_css">  

            <div>
                {v_allow_new === 1 && (
                    <button className="btn_save_css"
                        // disabled={disableSave}
                        value = 'INSERT'
                        onClick={createRecordFunction}>
                        Save
                    </button>
                )}
            </div>                                                   

            <div>
                <button className="btn_back_css"
                    value = 'BTN_BACK'
                    onClick={handleButtonValue}>
                    Back
                </button>
            </div>        
                
        </div>
        
    </div>
</nav>
   
</form>
:null
}
</>
);};

export default StudentMasterDetailU;
